<template>
  <div>
    <b-form inline @submit.prevent="query">
      <div class="text-left w-25 p-1">
        <label class="d-block">{{$t('SEARCH_FROM_DATE_LABEL') | capitalize}}</label>
        <b-datepicker class="w-100" :state="state('from')" v-model="$v.form.from.$model"></b-datepicker>
      </div>

      <div class="text-left w-25 p-1">
        <label class="d-block">{{$t('SEARCH_TO_DATE_LABEL') | capitalize}}</label>
        <b-datepicker class="w-100" :state="state('to')" v-model="$v.form.to.$model"></b-datepicker>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{$t('COMPANY_FILTER_LABEL') | capitalize}}</label>
        <company-single-selector @instance="setCompany" class="w-100 mb-2" v-model="$v.form.companyId.$model" value-type="id"
                                 :undefined-means="$t('ALL_COMPANIES_LABEL') | capitalize"></company-single-selector>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{$t('DRIVER_FILTER_LABEL') | capitalize}}</label>
        <driver-single-selector class="w-100 mb-2" v-model="$v.form.driverId.$model" value-type="id"
                                :undefined-means="$t('ALL_DRIVERS_LABEL') | capitalize"></driver-single-selector>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{$t('DRIVER_FATHER_LAST_NAME_LABEL') | capitalize}}</label>
        <b-input class="w-100 mb-2" v-model="$v.form.lastName.$model"></b-input>
      </div>

      <b-button type="submit" variant="primary">
        <b-icon-circle-fill animation="throb" v-if="loading"></b-icon-circle-fill>
        <b-icon-search v-else></b-icon-search>
        {{ $t('QUERY_LABEL') | capitalize }}
      </b-button>
    </b-form>

    <div v-if="data && data.length > 0" class="mt-2" style="height: 800px !important;">
      <h1 class="text-center">{{ $t('PAYROLL_REPORT_TITLE', {general: company ? company.name : $t('PAYROLL_REPORT_TITLE_GENERAL')}) }}</h1>
      <h2 class="text-center">{{ $t('SEARCH_FROM_DATE_LABEL') }} {{ form.from | date }} {{ $t('SEARCH_TO_DATE_LABEL') }}
        {{ form.to | date }}</h2>
      <b-table style="height: 800px !important;" :sticky-header="true" custom-foot striped hover :fields="fields" :items="data">
        <template #head(route)>
          {{ $t('PAYROLL_REPORT_ROUTE_LABEL') | capitalize }}
        </template>
        <template #head(associate)>
          {{ $t('PAYROLL_REPORT_ASSOCIATE_LABEL') | capitalize }}
        </template>
        <template #cell(associate)="data">
          {{ (data.item.associate === "1" ? $t('DRIVER_TYPE_ASSOCIATE') : $t('DRIVER_TYPE_EMPLOYEE')) | capitalize }}
        </template>
        <template #head(economic)>
          {{ $t('PAYROLL_REPORT_ECO_LABEL') | capitalize }}
        </template>
        <template #head(driver)>
          {{ $t('PAYROLL_REPORT_DRIVER_LABEL') | capitalize }}
        </template>
        <template #head(payment)>
          {{ $t('PAYROLL_REPORT_PAYMENT_LABEL') | capitalize }}
        </template>
        <template #cell(payment)="data">
          {{ (data.item.payment === "1" ? $t('DRIVER_PAYMENT_CASH') : $t('DRIVER_PAYMENT_PAYROLL')) | capitalize }}
        </template>
        <template #head(bus)>
          {{ $t('PAYROLL_REPORT_BUS_LABEL') | capitalize }}
        </template>
        <template #head(monday)>
          {{ $t('PAYROLL_REPORT_MONDAY_LABEL') | capitalize }}
        </template>
        <template #head(tuesday)>
          {{ $t('PAYROLL_REPORT_TUESDAY_LABEL') | capitalize }}
        </template>
        <template #head(wednesday)>
          {{ $t('PAYROLL_REPORT_WEDNESDAY_LABEL') | capitalize }}
        </template>
        <template #head(thursday)>
          {{ $t('PAYROLL_REPORT_THURSDAY_LABEL') | capitalize }}
        </template>
        <template #head(friday)>
          {{ $t('PAYROLL_REPORT_FRIDAY_LABEL') | capitalize }}
        </template>
        <template #head(saturday)>
          {{ $t('PAYROLL_REPORT_SATURDAY_LABEL') | capitalize }}
        </template>
        <template #head(sunday)>
          {{ $t('PAYROLL_REPORT_SUNDAY_LABEL') | capitalize }}
        </template>
        <template #head(total)>
          {{ $t('PAYROLL_REPORT_TOTAL_LABEL') | capitalize }}
        </template>

        <!-- Default fall-back custom formatted footer cell -->
        <template #custom-foot>
          <tr>
            <td colspan="6" class="text-right"><b>{{ $t('PAYROLL_REPORT_TOTAL_LABEL') | capitalize }}</b></td>
            <td colspan="1">{{getTotal('monday')}}</td>
            <td colspan="1">{{getTotal('tuesday')}}</td>
            <td colspan="1">{{getTotal('wednesday')}}</td>
            <td colspan="1">{{getTotal('thursday')}}</td>
            <td colspan="1">{{getTotal('friday')}}</td>
            <td colspan="1">{{getTotal('saturday')}}</td>
            <td colspan="1">{{getTotal('sunday')}}</td>
            <td>{{ total }}</td>
          </tr>
        </template>
      </b-table>
    </div>
    <div v-else>
      <h1 class="text-center">{{$t('REPORTS_NO_DATA') | capitalize}}</h1>
    </div>
  </div>

</template>

<script>
import {CapitalFilter, DateFilter, Form, SendsMessages} from "@/mixins";
import {required} from "vuelidate/lib/validators";
import Reports from "@/reports/index";
import moment from "moment";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import DriverSingleSelector from "@/drivers/DriverSingleSelector";

export default {
  name: "PayrollReport",
  components: {DriverSingleSelector, CompanySingleSelector},
  mixins: [CapitalFilter, SendsMessages, Form, DateFilter],
  data() {
    return {
      loading: false,
      data: [],
      company: undefined,
      fields: ['route', 'associate', 'bus', 'economic', 'driver', 'payment', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'total'],
      form: {
        from: new Date(),
        to: new Date(),
        companyId: undefined,
        driverId: undefined,
        lastName: undefined
      }
    }
  },
  methods: {
    setCompany(val) {
      console.log(val);
      this.company = val;
    },
    async query() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return;
        }
        this.loading = true;
        this.data = [];
        const resp = await Reports.payroll(this.form);
        this.data = resp.data;
      } catch (e) {
        this.sendError('ERROR_LOADING_REPORT_PAYROLL', {}, e);
      } finally {
        this.loading = false;
      }
    },
    getTotal(day) {
      if (this.data && this.data.length > 0) {
        return this.data.map(x => x[day]).reduce((a, b) => a + b);
      } else {
        return 0;
      }
    }
  },
  computed: {
    total() {
      if (this.data && this.data.length > 0) {
        return this.data.map(x => x.total).reduce((a, b) => a + b);
      } else {
        return 0;
      }
    }
  },
  validations: {
    form: {
      from: {required},
      to: {
        required, min: (value, vm) => {
          return moment(value).toDate() >= moment(vm.from).toDate();
        }
      },
      companyId: {},
      driverId: {},
      lastName: {},
    }
  }
}
</script>

<style scoped>
.b-table-sticky-header {
  max-height: 900px !important;
  height: 800px !important;
}
</style>